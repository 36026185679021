<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> / <router-link to="/departments">Departments</router-link> /
          {{ department.name }}
        </h1>
      </v-flex>
      <v-flex xs12 style="border-radius: 10px" v-if="department.under">
        <h4 class="primary--text">
          <span
            >{{ department.under.name }}
            <v-icon class="ml-5 mr-5 primary--text"
              >mdi-arrow-right-thin</v-icon
            ></span
          ><span>{{ department.name }}</span>
        </h4>
      </v-flex>
      <v-flex xs12 class="mt-10" v-if="users.length == 0">
        <div class="primary--text pa-0 ma-0 grey darken-4 pa-4 d-flex align-center" style="border-radius: 5px;">
          <v-icon class="mr-4 primary--text rotating-icon" style="font-size: 22pt;">mdi-alert-decagram-outline</v-icon>
          <p class="pa-0 ma-0">No accounts found under this department!</p>
        </div>
       
      </v-flex>
      <v-flex xs12 class="mt-5">
        <v-layout row wrap class="">
          <v-flex
            xs12
            md3
            lg2
            class="pa-2"
            v-for="item in users"
            :key="item._id"
            style="cursor: pointer"
          >
            <div
              class="pa-2"
              style="background-color: #212121; height: 100%;border-radius: 5px;"
              @click="viewAccount(item._id)"
            >
              <img
                :src="item.display_picture"
                v-if="item.display_picture"
                width="100%"
              />
              <div style="padding-top: 100%" class="black mb-1" v-else></div>
              <h3>{{ item.name }}</h3>
              <h4 class="primary--text">{{ item.department.name }}</h4>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Axios from "axios";
import { BASE_URL } from "../../config";
export default {
  data() {
    return {
      department: {},
      users: [],
    };
  },
  mounted() {
    this.fetchDepartment();
    this.fetchTeam();
  },
  methods: {
    async fetchDepartment() {
      let url = BASE_URL + "/department/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.department = data;
    },
    async fetchTeam() {
      let url = BASE_URL + "/user?type=department&department="+this.$route.params.id;
      let { data } = await Axios.get(url);
      this.users = data;
    },
  },
};
</script>

<style>
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .rotating-icon {
      display: inline-block;
      font-size: 24px; /* You can adjust the size as needed */
      animation: rotate 2s linear infinite; /* Adjust the duration and timing function as needed */
    }
  </style>